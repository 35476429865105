.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

.unseen {
  font-weight: 500 !important;
  font-size: 13px !important;
  color : black;
  font-family: var(--bs-text-medium);
}


.msgChecks{
  display: flex;
}
.msgChecks svg:first-child{
  z-index: 5;
  background-color: white;
}
.msgChecks svg:last-child{
  z-index: -1;
  margin-left: -8px;
}